
import { Component, Vue, Prop } from "vue-property-decorator";
import { LineType } from "@/entities/monitor/LineType";

@Component
export default class LineImage extends Vue {
  @Prop({ type: String })
  private lineType!: LineType;
  @Prop({ type: String })
  private lineName!: string | null;

  public get isTramOrBus(): boolean {
    return (
      this.lineType === LineType.Tram ||
      this.lineType === LineType.Bus ||
      this.lineType === LineType.BusNight ||
      this.lineType === LineType.BusRegion
    );
  }

  public get imgTextClass(): string {
    if (this.isLargeFontNecessary) {
      return "large";
    } else if (this.isSmallFontNecessary) {
      return "small";
    } else {
      return "";
    }
  }

  public get isLargeFontNecessary(): boolean {
    return this.lineName != null && this.lineName.length <= 2;
  }

  public get isSmallFontNecessary(): boolean {
    return this.lineName != null && this.lineName.length > 3;
  }

  public get urlToLineImage(): string | null {
    switch (this.lineType) {
      case LineType.MetroU1:
        return require("@/assets/img/u1_abfahrtsmonitor.svg");
      case LineType.MetroU2:
        return require("@/assets/img/u2_abfahrtsmonitor.svg");
      case LineType.MetroU3:
        return require("@/assets/img/u3_abfahrtsmonitor.svg");
      case LineType.MetroU4:
        return require("@/assets/img/u4_abfahrtsmonitor.svg");
      case LineType.MetroU5:
        return require("@/assets/img/u5_abfahrtsmonitor.svg");
      case LineType.MetroU6:
        return require("@/assets/img/u6_abfahrtsmonitor.svg");
      case LineType.Wlb:
        return require("@/assets/img/WLB_abfahrtsmonitor.svg");
      case LineType.Cat:
        return require("@/assets/img/CAT_abfahrtsmonitor.svg");
      case LineType.TrainExpress:
        return require("@/assets/img/sbahn_abfahrtsmonitor.svg");
      case LineType.Tram:
        return require("@/assets/img/strassenbahn_hintergrund_abfahrtsmonitor.svg");
      case LineType.Bus:
      case LineType.BusNight:
      case LineType.BusRegion:
        return require("@/assets/img/bus_hintergrund_abfahrtsmonitor.svg");
      default:
        return null;
    }
  }
}
