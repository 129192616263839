
import Vue from "vue";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { Prop, Component } from "vue-property-decorator";
import MalfunctionDisturbanceInfoBox from "@/components/disturbance/MalfunctionDisturbanceInfoBox.vue";
import ElevatorDisturbanceInfoBox from "@/components/disturbance/ElevatorDisturbanceInfoBox.vue";
import { DisturbanceInfoType } from "@/entities/monitor/DisturbanceInfoType";
import { PaginationInfo } from "@/entities/monitor/PaginationInfo";

@Component({
  components: {
    MalfunctionDisturbanceInfoBox,
    ElevatorDisturbanceInfoBox,
  },
})
export default class DisturbanceInfoBox extends Vue {
  @Prop({ required: true })
  private disturbance!: DisturbanceInfo;

  @Prop({ default: null })
  private paginationInfo!: PaginationInfo | null;

  private get currentDisturbanceIsMalfunction(): boolean {
    return this.disturbance != null && this.disturbance.type === DisturbanceInfoType.Malfunction;
  }
  private get currentDisturbanceIsElevator(): boolean {
    return this.disturbance != null && this.disturbance.type === DisturbanceInfoType.Elevator;
  }
}
