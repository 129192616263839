
import { Vue, Prop, Component } from "vue-property-decorator";
import { StationInfo } from "@/entities/app-state/StationInfo";
import { LineDetails } from "@/entities/monitor/LineDetails";
import LineInfo from "@/components/station-info/LineInfo.vue";
import StationHeader from "@/components/station-info/StationHeader.vue";
import { isLineDetails } from "@/util/isLineDetails";
import { isStationInfo } from "@/util/isStationInfo";

@Component({
  components: {
    LineInfo,
    StationHeader,
  },
})
export default class DynamicInfoLine extends Vue {
  @Prop({ type: Object })
  private item!: StationInfo | LineDetails;

  public isStationInfo(item: StationInfo | LineDetails): item is StationInfo {
    return isStationInfo(item);
  }

  private isLineDetails(item: StationInfo | LineDetails): item is LineDetails {
    return isLineDetails(item);
  }
}
