
import Vue from "vue";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { Prop, Component } from "vue-property-decorator";
import LineImage from "@/components/line-image/LineImage.vue";
import { DisturbedLine } from "@/entities/monitor/DisturbedLine";
import { PaginationInfo } from "@/entities/monitor/PaginationInfo";

@Component({
  components: {
    LineImage,
  },
})
export default class ElevatorDisturbanceInfoBox extends Vue {
  @Prop({ required: true })
  private disturbance!: DisturbanceInfo;

  @Prop({ default: null })
  private paginationInfo!: PaginationInfo | null;

  private get showPaginationInfo(): boolean {
    return this.paginationInfo != null && !(this.paginationInfo.current === 1 && this.paginationInfo.total === 1);
  }

  private get reason(): string {
    return this.disturbance.reason;
  }

  private get affectedLines(): DisturbedLine[] {
    return this.disturbance.affectedLines;
  }

  private get message(): string {
    return this.disturbance?.message ?? "";
  }
}
