import axios, { AxiosResponse } from "axios";
import { isNullOrWhitespace } from "@/util/isNullOrWhitespace";

export class ApiVersionService {
  private static sessionStorageKey(): string {
    return "api-version";
  }

  public async getCurrentVersion(): Promise<string> {
    const res: AxiosResponse = await axios.get("/healthcheck");
    return res.headers["wl-partner-screen-version"];
  }

  public hasCurrentVersion(): boolean {
    return !isNullOrWhitespace(sessionStorage.getItem(ApiVersionService.sessionStorageKey()));
  }

  public async updateCurrentVersion(): Promise<void> {
    const currentVersion = await this.getCurrentVersion();
    sessionStorage.setItem(ApiVersionService.sessionStorageKey(), currentVersion);
  }

  public async hasVersionChanged(): Promise<boolean> {
    if (!this.hasCurrentVersion()) {
      await this.updateCurrentVersion();
    }
    const previousVersion = sessionStorage.getItem(ApiVersionService.sessionStorageKey());
    const currentVersion = await this.getCurrentVersion();

    return previousVersion != currentVersion;
  }
}
