var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"dynamicInfoContainer",staticClass:"info"},[_c('div',{staticClass:"info-column"},[_vm._l((_vm.firstColumnItemsToShow),function(item,index){return _c('DynamicInfoLine',{key:_vm.getKeyForItem(item),class:{
        'first-line-info': _vm.isFirstLineDetailOfStation(item, index, _vm.firstColumnItemsToShow),
        'last-line-info': _vm.isLastLineDetailOfStation(item, index, _vm.firstColumnItemsToShow),
      },attrs:{"item":item}})}),_vm._l((_vm.firstColumnDynamicDisturbances),function(disturbance,index){return _c('DisturbanceInfoBox',{key:_vm.keyForDisturbance(disturbance, index),staticClass:"mb-4",attrs:{"disturbance":disturbance}})})],2),_c('div',{staticClass:"info-column"},[_vm._l((_vm.secondColumnItemsToShow),function(item,index){return _c('DynamicInfoLine',{key:_vm.getKeyForItem(item),class:{
        'first-line-info': _vm.isFirstLineDetailOfStation(item, index, _vm.secondColumnItemsToShow),
        'last-line-info': _vm.isLastLineDetailOfStation(item, index, _vm.secondColumnItemsToShow),
      },attrs:{"item":item}})}),_vm._l((_vm.secondColumnDynamicDisturbances),function(disturbance,index){return _c('DisturbanceInfoBox',{key:_vm.keyForDisturbance(disturbance, index),staticClass:"mb-4",attrs:{"disturbance":disturbance}})})],2),_c('div',{staticClass:"info-column info-column--last"},[_c('DisturbanceContainer',{attrs:{"disturbances":_vm.fixedDisturbances},on:{"refresh-disturbances":_vm.refreshDisturbances}}),_c('div',{staticClass:"invisible"},[_c('DisturbanceInfoBox',{ref:"disturbanceMeasurementContainer",staticClass:"mb-4",attrs:{"disturbance":_vm.disturbanceToMeasure}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }