
import { Component, Vue, Prop } from "vue-property-decorator";
import { Line } from "@/entities/monitor/Line";
import LineImage from "@/components/line-image/LineImage.vue";
import LineTitle from "@/components/station-info/LineTitle.vue";
import LineDeparture from "@/components/station-info/LineDeparture.vue";

@Component({
  components: {
    LineImage,
    LineTitle,
    LineDeparture,
  },
})
export default class LineInfo extends Vue {
  @Prop({ type: Object })
  private line!: Line;

  public get showSecondDeparture(): boolean {
    return this.line.departures[1] != null;
  }

  public get showThirdDeparture(): boolean {
    return this.line.departures[2] != null;
  }
}
