
import { Vue, Prop, Ref, Component } from "vue-property-decorator";
import { MonitorInfo } from "@/entities/monitor/MonitorInfo";
import DynamicInfoLine from "@/components/station-info/DynamicInfoLine.vue";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { StationInfo } from "@/entities/app-state/StationInfo";
import { isLineDetails } from "@/util/isLineDetails";
import { isStationInfo } from "@/util/isStationInfo";
import { hasValue } from "@/util/hasValue";
import { LineDetails } from "@/entities/monitor/LineDetails";
import DisturbanceContainer from "@/components/disturbance/DisturbanceContainer.vue";
import DisturbanceInfoBox from "@/components/disturbance/DisturbanceInfoBox.vue";

@Component({
  components: {
    DynamicInfoLine,
    DisturbanceContainer,
    DisturbanceInfoBox,
  },
})
export default class DynamicInfoContainer extends Vue {
  @Prop({ type: Object })
  private monitorInfo!: MonitorInfo;

  @Prop({ type: Array })
  private disturbances!: DisturbanceInfo[];

  @Ref("dynamicInfoContainer")
  private readonly dynamicInfoContainer!: HTMLDivElement;

  @Ref("disturbanceMeasurementContainer")
  private readonly disturbanceMeasurementContainer!: DisturbanceInfoBox;

  private disturbanceToMeasure: DisturbanceInfo | null = null;

  private firstColumnDynamicDisturbances: DisturbanceInfo[] = [];
  private secondColumnDynamicDisturbances: DisturbanceInfo[] = [];

  private fixedDisturbances: DisturbanceInfo[] = [];

  private firstColumnItemsToShow: Array<StationInfo | LineDetails> = [];
  private secondColumnItemsToShow: Array<StationInfo | LineDetails> = [];

  private resizeObserver: ResizeObserver | null = null;

  /**
   * Height in rem of a row.
   */
  private readonly relativeRowHeight = 3.1875;

  /**
   * Height in rem of the header row, border, and the padding below.
   */
  private readonly relativeHeaderRowHeight = 4.625;

  /**
   * Height in rem of the padding and margin of the last row.
   */
  private readonly relativePaddingBottomLastRow = 1.625;

  private disturbanceInfoUnwatch: (() => void) | null = null;

  public async mounted(): Promise<void> {
    this.resizeObserver = new ResizeObserver(async () => {
      await this.updateItemsToShow();
    });
    this.resizeObserver.observe(document.documentElement);
    this.$watch(
      "monitorInfo",
      async () => {
        await this.updateItemsToShow();
      },
      { immediate: true }
    );
  }

  public beforeDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private refreshDisturbances(): void {
    this.$emit("refresh-disturbances");
  }

  private keyForDisturbance(disturbance: DisturbanceInfo, index: number): string {
    if (disturbance == null) {
      return index.toString();
    }
    return `${index}-${disturbance.type}-${disturbance.message}-${
      disturbance.reason
    }-${disturbance.affectedLines.reduce((prev, current) => prev + current.lineName, "")}`;
  }

  private isFirstLineDetailOfStation(
    item: LineDetails | StationInfo,
    index: number,
    items: Array<StationInfo | LineDetails>
  ): boolean {
    if (!isLineDetails(item)) {
      return false;
    }

    return isStationInfo(items[index - 1]);
  }

  private isLastLineDetailOfStation(
    item: LineDetails | StationInfo,
    index: number,
    items: Array<StationInfo | LineDetails>
  ): boolean {
    if (!isLineDetails(item)) {
      return false;
    }

    return !isLineDetails(items[index + 1]);
  }

  private getKeyForItem(item: LineDetails | StationInfo): string {
    if (item == null) {
      return "";
    }
    if (isLineDetails(item)) {
      return `line-${item.stationInfoId}-${item.order}`;
    }
    if (isStationInfo(item)) {
      return `station-${item.id}`;
    }
    return "";
  }

  private getRemBase(): number {
    const rootComputedStyles = window.getComputedStyle(window.document.documentElement);
    const remBase = window.parseFloat(rootComputedStyles.fontSize);
    return remBase;
  }

  private calculateSpace(): {
    singleColumnHeight: number;
    rowHeight: number;
    headerRowHeight: number;
    paddingBottomLastLine: number;
  } {
    const remBase = this.getRemBase();

    const containerStyles = window.getComputedStyle(this.dynamicInfoContainer);
    const singleColumnHeight = Math.floor(window.parseFloat(containerStyles.height));
    const rowHeight = Math.floor(this.relativeRowHeight * remBase);
    const headerRowHeight = Math.floor(this.relativeHeaderRowHeight * remBase);
    const paddingBottomLastLine = Math.floor(this.relativePaddingBottomLastRow * remBase);

    return {
      singleColumnHeight,
      rowHeight,
      headerRowHeight,
      paddingBottomLastLine,
    };
  }

  private get flattenedMonitorInfo(): {
    first: Array<StationInfo | LineDetails>;
    second: Array<StationInfo | LineDetails>;
  } {
    const first: Array<StationInfo | LineDetails> = [];
    const firstStation = this.monitorInfo?.stations?.[0];
    if (firstStation && firstStation?.lines?.length) {
      first.push({ id: firstStation.id, name: firstStation.name });
      const sortedLines = firstStation.lines.slice(0).sort((l1, l2) => l1.order - l2.order);
      sortedLines.forEach((line) => first.push({ ...line, stationInfoId: firstStation.id }));
    }

    const second: Array<StationInfo | LineDetails> = [];
    const secondStation = this.monitorInfo?.stations?.[1];
    if (secondStation && secondStation?.lines?.length) {
      second.push({ id: secondStation.id, name: secondStation.name });
      const sortedLines = secondStation.lines.slice(0).sort((l1, l2) => l1.order - l2.order);
      sortedLines.forEach((line) => second.push({ ...line, stationInfoId: secondStation.id }));
    }

    return { first, second };
  }

  private howManyItemsFit(): {
    first: { items: Array<StationInfo | LineDetails>; spaceLeft: number };
    second: { items: Array<StationInfo | LineDetails>; spaceLeft: number };
  } {
    const allItems = this.flattenedMonitorInfo;
    const calculation = this.calculateSpace();
    let firstColumnSpaceLeft = calculation.singleColumnHeight;
    const firstColumn: Array<StationInfo | LineDetails> = [];

    for (let index = 0; index < allItems.first.length; index++) {
      const item = allItems.first[index];
      let requiredSpace = 0;
      if (isStationInfo(item)) {
        requiredSpace += calculation.headerRowHeight;
      } else if (isLineDetails(item)) {
        requiredSpace += calculation.rowHeight;
      }

      if (firstColumnSpaceLeft - requiredSpace <= 0) {
        firstColumnSpaceLeft = 0;
        break;
      }

      firstColumnSpaceLeft -= requiredSpace;
      firstColumn.push(item);
    }

    const secondColumn: Array<StationInfo | LineDetails> = [];
    let secondColumnSpaceLeft = calculation.singleColumnHeight;

    const secondStationRequiredSpace = allItems.second.reduce((requiredSpace, item) => {
      if (isStationInfo(item)) {
        return requiredSpace + calculation.headerRowHeight;
      } else if (isLineDetails(item)) {
        return requiredSpace + calculation.rowHeight;
      }
      return requiredSpace;
    }, calculation.paddingBottomLastLine);

    if (firstColumnSpaceLeft >= secondStationRequiredSpace) {
      firstColumn.push(...allItems.second);
      firstColumnSpaceLeft -= secondStationRequiredSpace;
    } else {
      firstColumnSpaceLeft = 0;
      for (let index = 0; index < allItems.second.length; index++) {
        const item = allItems.second[index];
        let requiredSpace = 0;
        if (isStationInfo(item)) {
          requiredSpace += calculation.headerRowHeight;
        } else if (isLineDetails(item)) {
          requiredSpace += calculation.rowHeight;
        }

        if (secondColumnSpaceLeft - requiredSpace <= 0) {
          secondColumnSpaceLeft = 0;
          break;
        }

        secondColumnSpaceLeft -= requiredSpace;
        secondColumn.push(item);
      }
    }

    return {
      first: {
        items: firstColumn,
        spaceLeft: firstColumnSpaceLeft,
      },
      second: {
        items: secondColumn,
        spaceLeft: secondColumnSpaceLeft,
      },
    };
  }

  private async howManyDisturbancesFit(): Promise<{
    firstColumnDynamic: DisturbanceInfo[];
    secondColumnDynamic: DisturbanceInfo[];
    fixed: DisturbanceInfo[];
  }> {
    if (this.disturbances.length === 0) {
      return { firstColumnDynamic: [], secondColumnDynamic: [], fixed: [] };
    }

    const { first, second } = this.howManyItemsFit();
    let firstColumnSpaceLeft = first.spaceLeft;
    let secondColumnSpaceLeft = second.spaceLeft;

    const sortedDisturbances = this.disturbances.slice(0).sort((a, b) => a.order - b.order);

    if (firstColumnSpaceLeft <= 0 && secondColumnSpaceLeft <= 0) {
      return { firstColumnDynamic: [], secondColumnDynamic: [], fixed: sortedDisturbances };
    }
    const fixedDisturbances: Array<DisturbanceInfo | null> = sortedDisturbances.slice(0);
    const firstColumnDynamicDisturbances: DisturbanceInfo[] = [];
    let currentDisturbanceIndex = 0;
    if (firstColumnSpaceLeft > 0) {
      for (; currentDisturbanceIndex < sortedDisturbances.length; currentDisturbanceIndex++) {
        const disturbance = sortedDisturbances[currentDisturbanceIndex];
        const disturbanceInfoHeight = await this.measureDisturbanceHeight(disturbance);
        if (firstColumnSpaceLeft - disturbanceInfoHeight <= 0) {
          firstColumnSpaceLeft = 0;
          break;
        }

        firstColumnSpaceLeft -= disturbanceInfoHeight;
        firstColumnDynamicDisturbances.push(disturbance);
        fixedDisturbances[currentDisturbanceIndex] = null;
      }
    }

    const secondColumnDynamicDisturbances: DisturbanceInfo[] = [];
    if (secondColumnSpaceLeft > 0) {
      for (; currentDisturbanceIndex < sortedDisturbances.length; currentDisturbanceIndex++) {
        const disturbance = sortedDisturbances[currentDisturbanceIndex];
        const disturbanceInfoHeight = await this.measureDisturbanceHeight(disturbance);
        if (secondColumnSpaceLeft - disturbanceInfoHeight <= 0) {
          secondColumnSpaceLeft = 0;
          break;
        }

        secondColumnSpaceLeft -= disturbanceInfoHeight;
        secondColumnDynamicDisturbances.push(disturbance);
        fixedDisturbances[currentDisturbanceIndex] = null;
      }
    }

    return {
      firstColumnDynamic: firstColumnDynamicDisturbances,
      secondColumnDynamic: secondColumnDynamicDisturbances,
      fixed: fixedDisturbances.filter(hasValue),
    };
  }

  private async measureDisturbanceHeight(disturbance: DisturbanceInfo): Promise<number> {
    this.disturbanceToMeasure = disturbance;
    await this.$nextTick();
    const disturbanceInfoHeight = this.outerHeight(this.disturbanceMeasurementContainer.$el as HTMLElement);
    this.disturbanceToMeasure = null;
    return disturbanceInfoHeight;
  }

  private async updateItemsToShow(): Promise<void> {
    const { first, second } = this.howManyItemsFit();
    this.firstColumnItemsToShow = first.items;
    this.secondColumnItemsToShow = second.items;
    if (this.disturbanceInfoUnwatch == null && (first.items.length > 0 || second.items.length > 0)) {
      // start watching disturbances when items are available
      this.disturbanceInfoUnwatch = this.$watch(
        "disturbances",
        async () => {
          const { firstColumnDynamic, secondColumnDynamic, fixed } = await this.howManyDisturbancesFit();
          this.firstColumnDynamicDisturbances = firstColumnDynamic;
          this.secondColumnDynamicDisturbances = secondColumnDynamic;
          this.fixedDisturbances = fixed;
        },
        { immediate: true }
      );
    }
  }

  private outerHeight(element: HTMLElement): number {
    const style = window.getComputedStyle(element);
    const height = element.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }
}
