export enum LineType {
  MetroU1 = "MetroU1",
  MetroU2 = "MetroU2",
  MetroU3 = "MetroU3",
  MetroU4 = "MetroU4",
  MetroU5 = "MetroU5",
  MetroU6 = "MetroU6",
  Tram = "Tram",
  Bus = "Bus",
  BusNight = "BusNight",
  BusRegion = "BusRegion",
  Wlb = "Wlb",
  Cat = "Cat",
  TrainExpress = "TrainExpress",
}
