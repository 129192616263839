
import Vue from "vue";
import { Component, Ref, Watch, Prop } from "vue-property-decorator";
import { Getters } from "@/store/constants";
import { MapConfiguration } from "@/entities/map/MapConfiguration";
import { LineType } from "../../entities/monitor/LineType";
import { path5MinLabel, path10MinLabel } from "@/labels/labelpath";
import { LineLocationInfo } from "../../entities/map/LineLocationInfo";

@Component
export default class GoogleMapsBackground extends Vue {
  @Prop({ required: true }) readonly configuration!: MapConfiguration;

  private isInitialized = false;

  @Ref("map")
  private readonly mapRef!: HTMLDivElement;

  private mapOptions(mapCenter: google.maps.LatLng, zoom: number): google.maps.MapOptions {
    return {
      center: mapCenter,
      clickableIcons: false,
      disableDefaultUI: true,
      draggable: false,
      gestureHandling: "none",
      keyboardShortcuts: false,
      scrollwheel: false,
      mapTypeId: "roadmap",
      zoom: zoom,
      zoomControl: false,
      styles: [
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [{ color: "#e6e6e6" }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [{ visibility: "off" }],
        },
        { featureType: "poi.attraction", stylers: [{ visibility: "off" }] },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        { featureType: "poi.government", stylers: [{ visibility: "off" }] },
        {
          featureType: "poi.medical",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "poi.medical",
          elementType: "geometry.fill",
          stylers: [{ color: "#f8e6e5" }],
        },
        {
          featureType: "poi.medical",
          elementType: "labels",
          stylers: [{ color: "#e6a5a6" }],
        },
        {
          featureType: "poi.medical",
          elementType: "labels.text",
          stylers: [{ color: "#a86b6b" }, { visibility: "on" }],
        },
        {
          featureType: "poi.medical",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ffffff" }, { visibility: "on" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#d0ecd0" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#5a8c5a" }],
        },
        {
          featureType: "poi.place_of_worship",
          elementType: "labels.icon",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "poi.place_of_worship",
          elementType: "labels.text",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.school",
          elementType: "geometry.fill",
          stylers: [{ color: "#78909c" }, { lightness: 75 }],
        },
        {
          featureType: "poi.school",
          elementType: "geometry.stroke",
          stylers: [{ color: "#78909c" }],
        },
        {
          featureType: "poi.school",
          elementType: "labels",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "poi.school",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        { featureType: "poi.sports_complex", stylers: [{ visibility: "off" }] },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [{ saturation: -100 }, { lightness: 20 }, { visibility: "on" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#878787" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#c7c7c7" }, { visibility: "on" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#737373" }],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry.fill",
          stylers: [{ color: "#f4f3d2" }],
        },
        { featureType: "transit.line", stylers: [{ visibility: "simplified" }] },
        {
          featureType: "transit.station",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [{ color: "#aedde5" }],
        },
        { featureType: "water", elementType: "labels.text", stylers: [{ color: "#6496af" }] },
      ],
    };
  }

  private getIconForLineType(type: LineType): string {
    switch (type) {
      case LineType.MetroU1:
        return require("@/assets/img/pin_u1.svg");
      case LineType.MetroU2:
        return require("@/assets/img/pin_u2.svg");
      case LineType.MetroU3:
        return require("@/assets/img/pin_u3.svg");
      case LineType.MetroU4:
        return require("@/assets/img/pin_u4.svg");
      case LineType.MetroU5:
        return require("@/assets/img/pin_u5.svg");
      case LineType.MetroU6:
        return require("@/assets/img/pin_u6.svg");
      case LineType.Tram:
        return require("@/assets/img/pin_strassenbahn.svg");
      case LineType.Bus:
        return require("@/assets/img/pin_bus.svg");
      case LineType.BusNight:
        return require("@/assets/img/pin_nightline.svg");
      case LineType.BusRegion:
        return require("@/assets/img/pin_bus.svg");
      case LineType.Wlb:
        return require("@/assets/img/pin_wlb.svg");
      case LineType.Cat:
        return require("@/assets/img/pin_cat.svg");
      case LineType.TrainExpress:
        return require("@/assets/img/pin_s_bahn.svg");
      default:
        return require("@/assets/img/empty.png");
    }
  }

  private initMap(): void {
    if (!this.isInitialized) {
      const center = new window.google.maps.LatLng(
        this.configuration.location.latitude,
        this.configuration.location.longitude
      );

      const mapCenter = new window.google.maps.LatLng(
        this.configuration.location.latitude,
        this.configuration.location.longitude + this.configuration.centerOffset / this.factorForScreen
      );

      const googleMapHandle = new window.google.maps.Map(this.mapRef, this.mapOptions(mapCenter, this.getZoomLevel));

      // Center marker
      new window.google.maps.Marker({
        position: this.transformToIconCenter(center),
        icon: {
          url: require("@/assets/img/pin_standort.svg"),
          scaledSize: new window.google.maps.Size(80 * this.factorForScreen, 80 * this.factorForScreen),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point((80 * this.factorForScreen) / 2, 80 * this.factorForScreen),
        },
        map: googleMapHandle,
        draggable: false,
        zIndex: 2,
      });

      // Station marker
      this.configuration.stationLocationInfos.forEach((stationLocationInfo) => {
        const filteredLineLocations = this.filterLineLocations(stationLocationInfo.lineLocations.slice(0));
        filteredLineLocations.forEach((lineLocation) => {
          new window.google.maps.Marker({
            position: this.transformToIconCenter(
              new window.google.maps.LatLng(lineLocation.location.latitude, lineLocation.location.longitude)
            ),
            icon: {
              url: this.getIconForLineType(lineLocation.type),
              scaledSize: new window.google.maps.Size(80 * this.factorForScreen, 80 * this.factorForScreen),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point((80 * this.factorForScreen) / 2, 80 * this.factorForScreen),
            },
            map: googleMapHandle,
            draggable: false,
            zIndex: 1,
          });
        });
      });

      // Walking radius 5 min
      new window.google.maps.Polyline(this.getWalkingDistanceOptions(googleMapHandle, center, 250));

      // Walking radius label 5 min
      const fiveMinLabelOffset = 0.00179;
      new window.google.maps.Marker({
        map: googleMapHandle,
        position: new window.google.maps.LatLng(center.lat() - fiveMinLabelOffset, center.lng() + fiveMinLabelOffset),
        icon: {
          path: path5MinLabel,
          strokeWeight: 1.4 * this.factorForScreen,
          strokeOpacity: 1,
          strokeColor: "#FF0000",
          scale: this.factorForScreen,
          rotation: -40,
        },
      });

      // Walking radius 10 min
      new window.google.maps.Polyline(this.getWalkingDistanceOptions(googleMapHandle, center, 500));

      // Walking radius label 10 min
      const tenMinLabelOffset = 0.00365;
      new window.google.maps.Marker({
        map: googleMapHandle,
        position: new window.google.maps.LatLng(center.lat() - tenMinLabelOffset, center.lng() + tenMinLabelOffset),
        icon: {
          path: path10MinLabel,
          strokeWeight: 1.4 * this.factorForScreen,
          strokeOpacity: 1,
          strokeColor: "#FF0000",
          scale: this.factorForScreen,
          rotation: -40,
        },
      });

      this.isInitialized = true;
    }
  }

  private transformToIconCenter(original: google.maps.LatLng): google.maps.LatLng {
    const iconLatOffset = -0.0003;
    const iconLngOffset = 0;
    return new window.google.maps.LatLng(original.lat() + iconLatOffset, original.lng() + iconLngOffset);
  }

  private getWalkingDistanceOptions(
    mapHandle: google.maps.Map,
    center: google.maps.LatLng,
    radius: number
  ): google.maps.PolylineOptions {
    return {
      path: this.computeCirclePath(center, radius),
      strokeColor: "#FF0000",
      strokeOpacity: 0,
      icons: [
        {
          icon: this.getLineSymbol(),
          offset: "0",
          repeat: `${10 * this.factorForScreen}px`,
        },
      ],
      map: mapHandle,
    };
  }

  private getLineSymbol(): google.maps.Symbol {
    return {
      path: "M 0,-1 0,1",
      strokeWeight: 2 * this.factorForScreen,
      strokeOpacity: 0.8,
      strokeColor: "#FF0000",
      scale: 2 * this.factorForScreen,
    };
  }

  private computeCirclePath(center: google.maps.LatLng, radius: number): google.maps.LatLng[] {
    const numPts = 512;
    const path = [];
    for (let i = 0; i < numPts; i++) {
      path.push(window.google.maps.geometry.spherical.computeOffset(center, radius, (i * 360) / numPts));
    }
    return path;
  }

  private get isGoogleMapsLoaded(): boolean {
    return this.$store.getters[Getters.IS_GOOGLE_MAPS_LOADED];
  }

  private get getZoomLevel(): number {
    let zoomLevel = this.configuration.zoomLevel;
    if (window.innerWidth >= 3840) {
      zoomLevel += 1;
    }
    return zoomLevel;
  }

  private get factorForScreen(): number {
    if (window.innerWidth >= 3840) {
      return 2;
    }
    return 1;
  }

  private filterLineLocations(lineLocationInfos: LineLocationInfo[]): LineLocationInfo[] {
    const minDistanceToDisplay = 50;

    for (let i = 0; i < lineLocationInfos.length; i++) {
      const firstLineLocation = lineLocationInfos[i];
      const firstLatLng = new window.google.maps.LatLng(
        firstLineLocation.location.latitude,
        firstLineLocation.location.longitude
      );

      for (let j = i + 1; j < lineLocationInfos.length; j++) {
        const currentLineLocation: LineLocationInfo = lineLocationInfos[j];
        if (firstLineLocation.type != currentLineLocation.type) {
          continue;
        }
        const currentLatLng = new window.google.maps.LatLng(
          currentLineLocation.location.latitude,
          currentLineLocation.location.longitude
        );
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(firstLatLng, currentLatLng);

        if (distance < minDistanceToDisplay) {
          lineLocationInfos.splice(j, 1);
          j--;
        }
      }
    }
    return lineLocationInfos;
  }

  @Watch("isGoogleMapsLoaded")
  onGoogleMapsLoaded(isLoaded: boolean): void {
    if (isLoaded && this.configuration != null) {
      this.initMap();
    }
  }

  @Watch("configuration")
  onConfiguration(configuration: MapConfiguration): void {
    if (this.isGoogleMapsLoaded && configuration != null) {
      this.initMap();
    }
  }
}
