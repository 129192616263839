
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import DynamicInfoContainer from "@/components/station-info/DynamicInfoContainer.vue";
import { Actions, Getters, Mutations } from "@/store/constants";
import { MonitorInfo } from "@/entities/monitor/MonitorInfo";
import { StationLocationInfo } from "@/entities/map/StationLocationInfo";
import { LoadingState } from "@/entities/LoadingState";
import GoogleMapsBackground from "@/components/maps-background/GoogleMapsBackground.vue";
import { MapConfiguration } from "@/entities/map/MapConfiguration";
import { Place } from "@/entities/app-state/Place";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { MobileCopyConfigForm } from "@/components/monitor/MobileCopyConfigForm.tsx";
import { ApiVersionService } from "@/services/ApiVersionService";
import { notNullOrWhitespace } from "@/util/notNullOrWhitespace";

@Component({
  components: {
    DynamicInfoContainer,
    GoogleMapsBackground,
    MobileCopyConfigForm,
  },
})
export default class Monitor extends Vue {
  private refreshRate = 30000; // 30 sec
  private fullPageReloadRefreshRate = 86400000; // 24 hours
  private licenceRequestRate = 60000; // 60 sec
  private intervalRefId: number | null = null;
  private fullPageReloadIntervalRefId: number | null = null;
  private licenceRequestIntervalRefId: number | null = null;
  private readonly mapZoomLevel = 17;
  private readonly mapOffsetFaktor = -0.006;
  private readonly apiVersionService = new ApiVersionService();

  // called by vue
  public created(): void {
    this.loadConfiguration();
  }

  // called by vue
  public beforeDestroy(): void {
    if (this.intervalRefId) {
      window.clearInterval(this.intervalRefId);
    }
    if (this.fullPageReloadIntervalRefId) {
      window.clearInterval(this.fullPageReloadIntervalRefId);
    }
    if (this.licenceRequestIntervalRefId) {
      window.clearInterval(this.licenceRequestIntervalRefId);
    }
    this.$store.commit(Mutations.RESET_MONITOR);
  }

  private get configurationLoadingState(): LoadingState {
    return this.$store.getters[Getters.CONFIGURATION_LOADING_STATE];
  }

  private get monitorInfo(): MonitorInfo | null {
    return this.$store.getters[Getters.MONITOR_INFO];
  }

  private get stationLocationInfo(): StationLocationInfo[] | null {
    return this.$store.getters[Getters.STATION_LOCATION_INFO];
  }

  private get hasRequestErrorLimitReached(): boolean {
    return this.$store.getters[Getters.REQUEST_ERROR_COUNT] >= 3;
  }

  private get hasLicenceRequestErrorLimitReached(): boolean {
    return this.$store.getters[Getters.LICENCE_ERROR_COUNT] >= 1;
  }

  private get currentPlace(): Place {
    return this.$store.getters[Getters.CONFIGURATION].place;
  }

  private get partnerLogoUrl(): string {
    return this.$store.getters[Getters.PARTNER_LOGO_URL];
  }

  private get lastLicenceCheckDate(): Date | null {
    return this.$store.getters[Getters.LAST_LICENCE_CHECK_DATE];
  }

  private get showPartnerLogo(): boolean {
    return notNullOrWhitespace(this.partnerLogoUrl);
  }

  private get calculateCenterOffset(): number {
    return this.mapOffsetFaktor * (screen.width / 1920);
  }

  private get mapConfiguration(): MapConfiguration | null {
    if (this.stationLocationInfo != null && this.stationLocationInfo.length > 0 && this.currentPlace != null) {
      const configuration = {
        location: { latitude: this.currentPlace.latitude, longitude: this.currentPlace.longitude },
        stationLocationInfos: this.stationLocationInfo,
        centerOffset: this.calculateCenterOffset,
        zoomLevel: this.mapZoomLevel,
      };
      return configuration;
    }

    return null;
  }

  public get configurationLoadFailed(): boolean {
    return this.configurationLoadingState === LoadingState.Failed;
  }

  public get hasError(): boolean {
    return this.hasRequestErrorLimitReached || this.configurationLoadFailed || this.hasLicenceRequestErrorLimitReached;
  }

  public get isXl(): boolean {
    // (this as any) necessary because of the missing types for vue-mq
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any).$mq === "xl";
  }

  @Watch("$route")
  private loadConfiguration(): void {
    this.$store.dispatch(Actions.LOAD_CONFIGURATION, { configurationId: this.$route.params.configurationId });
  }

  @Watch("configurationLoadingState")
  private async startTimerWhenConfigurationIsReady(loadingState: LoadingState): Promise<void> {
    if (loadingState === LoadingState.Loaded && this.intervalRefId == null) {
      this.intervalRefId = window.setInterval(this.refreshMonitorInfo, this.refreshRate);
      this.fullPageReloadIntervalRefId = window.setInterval(() => {
        location.reload();
      }, this.fullPageReloadRefreshRate);
      await this.sendLicenceRequest();
      this.refreshMonitorInfo();
      this.refreshDisturbances();
    }

    if (loadingState == LoadingState.Loaded) {
      this.$store.dispatch(Actions.LOAD_PARTNER_LOGO);
    }
  }

  @Watch("lastLicenceCheckDate")
  private onLastLicenceCheckDateChanged(): void {
    this.licenceRequestIntervalRefId = window.setInterval(this.sendLicenceRequest, this.licenceRequestRate);
  }

  @Watch("hasError")
  private onError(): void {
    if (this.hasRequestErrorLimitReached || this.configurationLoadFailed) {
      this.$router.push(`/error/${this.$route.params.configurationId}`);
    } else if (this.hasLicenceRequestErrorLimitReached) {
      this.$router.push(`/error/${this.$route.params.configurationId}/licence`);
    }
  }

  private async refreshMonitorInfo(): Promise<void> {
    if (await this.apiVersionService.hasVersionChanged()) {
      console.log(await this.apiVersionService.getCurrentVersion());
      this.apiVersionService.updateCurrentVersion();
      location.reload();
    }

    this.$store.dispatch(Actions.VALIDATE_CONFIGURATION, {
      configurationId: this.$route.params.configurationId,
    });
    this.$store.dispatch(Actions.LOAD_MONITOR_INFO);
  }

  private get disturbances(): DisturbanceInfo[] {
    return this.$store.getters[Getters.DISTURBANCE_INFO] ?? [];
  }

  private refreshDisturbances(): void {
    this.$store.dispatch(Actions.LOAD_DISTURBANCE_INFO);
  }

  private async sendLicenceRequest(): Promise<void> {
    if (this.licenceRequestIntervalRefId) {
      window.clearInterval(this.licenceRequestIntervalRefId);
      this.licenceRequestIntervalRefId = null;
    }
    await this.$store.dispatch(Actions.SEND_LICENCE_REQUEST);
  }
}
