
import Vue from "vue";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { PaginationInfo } from "@/entities/monitor/PaginationInfo";
import { Prop, Component } from "vue-property-decorator";
import LineImage from "@/components/line-image/LineImage.vue";
import { DisturbedLine } from "../../entities/monitor/DisturbedLine";

@Component({
  components: {
    LineImage,
  },
})
export default class MalfunctionDisturbanceInfoBox extends Vue {
  @Prop({ required: true })
  private disturbance!: DisturbanceInfo;

  @Prop({ default: null })
  private paginationInfo!: PaginationInfo | null;

  private get showPaginationInfo(): boolean {
    return this.paginationInfo != null && !(this.paginationInfo.current === 1 && this.paginationInfo.total === 1);
  }

  private get affectedLines(): DisturbedLine[] {
    return this.disturbance.affectedLines;
  }

  private get reason(): string {
    if (this.disturbance === null) {
      return "";
    }

    const pattern = " : ";
    const startIndex = this.disturbance.reason.indexOf(pattern);

    return startIndex === -1 ? this.disturbance.reason : this.disturbance.reason.substring(startIndex + pattern.length);
  }

  private get message(): string {
    return this.disturbance?.message ?? "";
  }
}
