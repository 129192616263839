
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { DepartureInfo } from "@/entities/monitor/DepartureInfo";

@Component
export default class LineDeparture extends Vue {
  @Prop({ type: Object })
  private departure!: DepartureInfo;

  public get isRealTimeDataAvailable(): boolean {
    return this.departure.realTimeCountDown != null;
  }
}
