
import { Vue, Prop, Component } from "vue-property-decorator";
import { StationInfo } from "@/entities/app-state/StationInfo";

@Component({})
export default class StationHeader extends Vue {
  @Prop({ type: Object })
  private stationInfo!: StationInfo;

  public get sanitizedStationName(): string {
    const regex = /(?:Wien\s(?!Mitte))?(?<sanitizedName>.+)/gi;
    const match = regex.exec(this.stationInfo.name);
    return match?.groups?.sanitizedName ?? this.stationInfo.name;
  }
}
