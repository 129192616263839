var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"line-info flex"},[_c('LineImage',{staticClass:"line-img",attrs:{"lineType":_vm.line.lineType,"lineName":_vm.line.name}}),_c('div',{staticClass:"text-container flex"},[_c('line-title',{class:{
        'line-title': !_vm.line.isDisturbed && !_vm.showThirdDeparture,
        'line-title-disturbed': _vm.line.isDisturbed && !_vm.showThirdDeparture,
        'line-title-three-stations': !_vm.line.isDisturbed && _vm.showThirdDeparture,
        'line-title-three-stations-disturbed': _vm.line.isDisturbed && _vm.showThirdDeparture,
      },attrs:{"line":_vm.line}}),(_vm.line.isDisturbed)?_c('div',[_vm._m(0)]):_vm._e(),_c('line-departure',{attrs:{"departure":_vm.line.departures[0]}}),(_vm.showSecondDeparture)?_c('div',{staticClass:"separator-departure"}):_vm._e(),(_vm.showSecondDeparture)?_c('line-departure',{attrs:{"departure":_vm.line.departures[1]}}):_vm._e(),(_vm.showThirdDeparture)?_c('div',{staticClass:"separator-departure"}):_vm._e(),(_vm.showThirdDeparture)?_c('line-departure',{attrs:{"departure":_vm.line.departures[2]}}):_vm._e(),(false)?_c('div',{staticClass:"departure plan-time"},[_vm._v(_vm._s(_vm.line.departures[0].planTime))]):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"disturbance-img"},[_c('img',{staticClass:"w-full",attrs:{"src":require("@/assets/img/stoerung_stoerungsinfo.svg"),"alt":""}})])
}]

export { render, staticRenderFns }