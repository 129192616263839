
import { Component, Vue, Prop } from "vue-property-decorator";
import { Line } from "@/entities/monitor/Line";
import { isNullOrWhitespace } from "@/util/isNullOrWhitespace";
import { notNullOrWhitespace } from "@/util/notNullOrWhitespace";

@Component
export default class LineTitle extends Vue {
  @Prop({ type: Object })
  private line!: Line;

  public get showOnlyFirstTowards(): boolean {
    return (
      (isNullOrWhitespace(this.secondDepartureTowards) || this.firstDepartureTowards == this.secondDepartureTowards) &&
      (isNullOrWhitespace(this.thirdDepartureTowards) || this.firstDepartureTowards == this.thirdDepartureTowards)
    );
  }

  public get showSecondTowards(): boolean {
    return !this.showOnlyFirstTowards && notNullOrWhitespace(this.secondDepartureTowards);
  }

  public get showThirdTowards(): boolean {
    return !this.showOnlyFirstTowards && notNullOrWhitespace(this.thirdDepartureTowards);
  }

  public get isMetroStation(): boolean {
    return this.line.lineType.toString().startsWith("Metro");
  }

  public get sanitizedFirstDepartureTowards(): string {
    return this.sanitizeLineName(this.firstDepartureTowards);
  }

  public get sanitizedSecondDepartureTowards(): string {
    return this.sanitizeLineName(this.secondDepartureTowards);
  }

  public get sanitizedThirdDepartureTowards(): string {
    return this.sanitizeLineName(this.thirdDepartureTowards);
  }

  public get showFirstDepartureMetroIcon(): boolean {
    return this.containsMetroIdentifier(this.firstDepartureTowards);
  }

  public get showSecondDepartureMetroIcon(): boolean {
    return this.containsMetroIdentifier(this.secondDepartureTowards) && this.showSecondTowards;
  }

  public get showThirdDepartureMetroIcon(): boolean {
    return this.containsMetroIdentifier(this.thirdDepartureTowards) && this.showThirdTowards;
  }

  public get showFirstDepartureTrainIcon(): boolean {
    return this.containsTrainIdentifier(this.firstDepartureTowards);
  }

  public get showSecondDepartureTrainIcon(): boolean {
    return this.containsTrainIdentifier(this.secondDepartureTowards) && this.showSecondTowards;
  }

  public get showThirdDepartureTrainIcon(): boolean {
    return this.containsTrainIdentifier(this.thirdDepartureTowards) && this.showThirdTowards;
  }

  public get firstDepartureTowards(): string {
    return this.line.departures[0]?.towards ?? "";
  }

  public get secondDepartureTowards(): string {
    return this.line.departures[1]?.towards ?? "";
  }

  public get thirdDepartureTowards(): string {
    return this.line.departures[2]?.towards ?? "";
  }

  private sanitizeLineName(dirtyName: string): string {
    const trainOrMetroPostfixRegex = /((?: S$)|(?: U$)|(?: SU$)|(?: US$)|(?: S U$)|(?: U S$))/g;
    let sanitizedName = dirtyName.replace(trainOrMetroPostfixRegex, "");
    if (this.isMetroStation) {
      sanitizedName = sanitizedName.toLowerCase();
    }
    return sanitizedName;
  }

  private containsMetroIdentifier(name: string): boolean {
    const metroIdentifierRegex = /(?:(?: U$)|(?: SU$)|(?: US$)|(?: S U$)|(?: U S$))/g;
    return metroIdentifierRegex.test(name);
  }

  private containsTrainIdentifier(name: string): boolean {
    const trainIdentifierRegex = /(?:(?: S$)|(?: SU$)|(?: US$)|(?: S U$)|(?: U S$))/g;
    return trainIdentifierRegex.test(name);
  }
}
