import Vue, { CreateElement, RenderContext, VNode } from "vue";

function loadSuccess(h: CreateElement, ctx: RenderContext): VNode {
  const currentUrl = window.location.href;
  const handleButtonClick = async (event: Event): Promise<void> => {
    event.preventDefault();
    await navigator.clipboard.writeText(currentUrl);
  };

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const byWienerLinienImg = require("@/assets/img/by-wiener-linien.svg");

  return (
    <div>
      <div class="form-field">
        <h2 class="text-formLabel text-danger uppercase">{ctx.parent.$t("monitor.copyLink.configurationComplete")}</h2>
        <div class="form-input-description">{ctx.parent.$t("monitor.copyLink.linkDescription")}</div>
        <input class="form-input" type="url" readonly value={currentUrl} />
      </div>
      <div class="form-field text-center">
        <button onClick={handleButtonClick} class="form-button">
          {ctx.parent.$t("monitor.copyLink.copy")}
        </button>
      </div>
      <div class="w-100 mt-5">
        <img class="m-auto w-32" src={byWienerLinienImg} alt="" />
      </div>
    </div>
  );
}

function loadFailed(h: CreateElement, ctx: RenderContext): VNode {
  return <p>{ctx.parent.$t("monitor.errors.configurationLoadFailed")}</p>;
}

export const MobileCopyConfigForm = Vue.extend({
  functional: true,
  name: "MobileCopyConfigForm",
  render(createElement: CreateElement, ctx: RenderContext): VNode {
    let body: VNode | null;
    if (ctx.props.configurationLoadFailed) {
      body = loadFailed(createElement, ctx);
    } else {
      body = loadSuccess(createElement, ctx);
    }
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const partnerScreenLogo = require("@/assets/img/wienmobil_monitor_logo.svg");

    return (
      <div v-else class="copy-link w-full">
        <div class="bg-white w-full p-3 lg:p-12 flex flex-col">
          <div class="w-100">
            <img class="m-auto" src={partnerScreenLogo} alt="" />
          </div>
          {body}
        </div>
      </div>
    );
  },
});
