
import Vue from "vue";
import { DisturbanceInfo } from "@/entities/monitor/DisturbanceInfo";
import { Prop, Watch, Component } from "vue-property-decorator";
import DisturbanceInfoBox from "./DisturbanceInfoBox.vue";
import { DisturbanceInfoType } from "../../entities/monitor/DisturbanceInfoType";
import { PaginationInfo } from "../../entities/monitor/PaginationInfo";

@Component({
  components: {
    DisturbanceInfoBox,
  },
})
export default class DisturbanceContainer extends Vue {
  @Prop({ required: true })
  private disturbances!: DisturbanceInfo[];

  private readonly refreshRate = 30000; // 30 sec
  private readonly pageChangeRate = 30000; // 30 sec

  private currentDisturbanceInfoIndex: number | null = null;

  private pageChangeIntervalRefId: number | null = null;
  private refreshIntervalRefId: number | null = null;
  private readonly maxMessageLength = 400;

  private readonly hellipChar = "\u2026";

  public created(): void {
    this.startDisturbanceRefresh(true);
  }

  public beforeDestroy(): void {
    if (this.pageChangeIntervalRefId != null) {
      window.clearInterval(this.pageChangeIntervalRefId);
    }
    if (this.refreshIntervalRefId != null) {
      window.clearInterval(this.refreshIntervalRefId);
    }
  }

  private get hasInfoToShow(): boolean {
    return this.currentDisturbanceInfo != null;
  }

  private get paginationInfo(): PaginationInfo | null {
    if (this.currentDisturbanceInfoIndex == null || this.totalNumberOfDisturbances == 0) {
      return null;
    }

    return { current: this.currentDisturbanceInfoIndex + 1, total: this.totalNumberOfDisturbances };
  }

  private get processedDisturbances(): DisturbanceInfo[] {
    return this.disturbances.slice(0).reduce((processed, disturbance) => {
      if (disturbance.message.trim().length <= this.maxMessageLength) {
        processed.push({ ...disturbance, message: disturbance.message.trim() });
        return processed;
      }

      let length = 0;
      const words = disturbance.message.trim().split(" ");
      const rawMessages: string[][] = [[]];
      words.forEach((word) => {
        length += word.length + 1;
        const messageIndex = Math.floor(length / this.maxMessageLength);
        if (rawMessages[messageIndex] == null) {
          rawMessages[messageIndex] = [];
        }
        rawMessages[messageIndex].push(word);
      });
      const oneDisturbancePerMessage = rawMessages
        .flatMap((rawMessage, index) => {
          let message = rawMessage.join(" ");
          if (index !== rawMessages.length - 1) {
            message = message.concat(this.hellipChar);
          }
          return message;
        })
        .map((message): DisturbanceInfo => ({ ...disturbance, message }));

      processed.push(...oneDisturbancePerMessage);
      return processed;
    }, [] as DisturbanceInfo[]);
  }

  private get totalNumberOfDisturbances(): number {
    return this.processedDisturbances.length;
  }

  private get currentDisturbanceInfo(): DisturbanceInfo | null {
    if (this.currentDisturbanceInfoIndex == null) {
      return null;
    }

    return this.processedDisturbances[this.currentDisturbanceInfoIndex];
  }

  private get currentDisturbanceIsMalfunction(): boolean {
    return this.currentDisturbanceInfo != null && this.currentDisturbanceInfo.type === DisturbanceInfoType.Malfunction;
  }
  private get currentDisturbanceIsElevator(): boolean {
    return this.currentDisturbanceInfo != null && this.currentDisturbanceInfo.type === DisturbanceInfoType.Elevator;
  }

  @Watch("disturbances")
  private onNewDisturbances(disturbances: DisturbanceInfo[]): void {
    this.currentDisturbanceInfoIndex = null;
    if (this.pageChangeIntervalRefId != null) {
      window.clearInterval(this.pageChangeIntervalRefId);
      this.pageChangeIntervalRefId = null;
    }
    if (disturbances.length === 0) {
      return;
    }
    if (this.refreshIntervalRefId != null) {
      window.clearInterval(this.refreshIntervalRefId);
      this.refreshIntervalRefId = null;
    }
    this.updateCurrentDisturbance();
    this.pageChangeIntervalRefId = window.setInterval(() => {
      this.updateCurrentDisturbance();
    }, this.pageChangeRate);
  }

  private updateCurrentDisturbance(): void {
    if (this.currentDisturbanceInfoIndex == null) {
      this.currentDisturbanceInfoIndex = 0;
    } else {
      this.currentDisturbanceInfoIndex++;
    }

    if (
      this.currentDisturbanceInfo != null &&
      this.currentDisturbanceInfoIndex < this.processedDisturbances.length - 1
    ) {
      return;
    }

    if (this.pageChangeIntervalRefId != null) {
      // All disturbances were cycled through. Stop the cycle.
      window.clearInterval(this.pageChangeIntervalRefId);
      this.pageChangeIntervalRefId = null;
    }

    if (this.processedDisturbances.length - 1 === this.currentDisturbanceInfoIndex) {
      // Last disturbance info in the list -> load new ones after some time
      this.startDisturbanceRefresh(false);
      return;
    }

    // We hit the last disturbance -> reset index and start reloading immediately
    this.currentDisturbanceInfoIndex = null;
    this.startDisturbanceRefresh(true);
  }

  private startDisturbanceRefresh(refreshImmediately: boolean): void {
    if (this.refreshIntervalRefId != null) {
      return;
    }
    this.refreshIntervalRefId = window.setInterval(() => {
      this.refreshDisturbances();
    }, this.refreshRate);
    if (refreshImmediately) {
      this.refreshDisturbances();
    }
  }

  private refreshDisturbances(): void {
    this.$emit("refresh-disturbances");
  }
}
